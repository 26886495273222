var md5 = require("md5");
var hostname = window.location.hostname;
console.log("hostname", hostname);

var api_base_url = false;
if (hostname === "localhost" || hostname.includes(".localhost"))
	api_base_url = "http://api.sdm.localhost";
else if (hostname.includes(".dev.")) api_base_url = "https://api.dev.yellgo.cloud";
else if (hostname.includes(".devaws.")) api_base_url = "https://api.devaws.yellgo.idspace.it";
else if (hostname.includes(".demo.")) api_base_url = "https://api.demo.yellgo.cloud";
else api_base_url = "https://api.yellgo.cloud";
console.log("api_base_url", api_base_url);

let token = null;

function oauth(mandant_id) {
	console.log("oauth", mandant_id);
	token = md5(mandant_id);
	console.log("token", token);
}

async function post(endpoint, request) {
	const response = await fetch(api_base_url + endpoint + "?token=" + token, {
		method: "POST",
		body: JSON.stringify(request),
	});

	const json = await response.json();

	return json;
}

export default { oauth, post };
