import { useEffect, useState } from "react";

function Clock() {
	const [clockState, setClockState] = useState();

	useEffect(() => {
		const date = new Date();
		setClockState(timeFormat(date));
		let myInterval = setInterval(() => {
			const date = new Date();
			setClockState(timeFormat(date));
		}, 1000);
		return () => {
			console.log("clearInterval Clock");
			clearInterval(myInterval);
		};
	}, []);

	const leadingZero = (num) => `0${num}`.slice(-2);

	const timeFormat = (date) => [date.getHours(), date.getMinutes()].map(leadingZero).join(":");

	return <h2>{clockState}</h2>;
}

export default Clock;
