import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./Toast.css";
import CheckIcon from "../../icons/CheckIcon";

const Toast = (props) => {
	const { toastList, autoDelete, autoDeleteTime } = props;
	const [list, setList] = useState(toastList);

	useEffect(() => {
		setList([...toastList]);
	}, [toastList]);

	useEffect(() => {
		console.log("autoDeleteTime", autoDeleteTime);
		const interval = setInterval(() => {
			if (autoDelete && toastList.length && list.length) {
				deleteToast(toastList[0].id);
			}
		}, autoDeleteTime);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line
	}, [toastList, autoDelete, autoDeleteTime, list]);

	const deleteToast = (id) => {
		const listItemIndex = list.findIndex((e) => e.id === id);
		const toastListItem = toastList.findIndex((e) => e.id === id);
		list.splice(listItemIndex, 1);
		toastList.splice(toastListItem, 1);
		setList([...list]);
	};

	return (
		<>
			{list.length > 0 && (
				<div className={`notification-container`}>
					<div className="content">
						{list.map((toast, i) => (
							<div
								key={i}
								className="notification"
								style={{ backgroundColor: toast.productionCenter?.color }}
							>
								<div className="notification-icon">
									<CheckIcon></CheckIcon>
								</div>
								{toast.productionCenter && <div>{toast.productionCenter.name}</div>}
								<div>
									<div className="notification-name">{toast.name}</div>
									<div className="notification-number">
										{toast.number ? toast.number : toast.id}
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</>
	);
};

Toast.propTypes = {
	toastList: PropTypes.array.isRequired,
	autoDelete: PropTypes.bool,
	autoDeleteTime: PropTypes.number,
};

export default Toast;
