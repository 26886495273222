import { useEffect, useState } from "react";

function List({
	productionsCenter,
	orders,
	cols = 1,
	titleSize = 40,
	titleLine = "normal",
	orderSize = 40,
	square = 0,
	settings,
	mode = "cols",
}) {
	const [filtererOrders, setFiltererOrders] = useState([]);

	useEffect(() => {
		if (orders) filterOrders();
	}, [orders]);

	const filterOrders = () => {
		//console.log("filterOrders", productionsCentersId);
		if (productionsCenter) {
			let res = [];
			for (const order of orders) {
				if (productionsCenter.id == order.production_center_id) res.push(order);
			}
			//console.log(res);
			setFiltererOrders(res);
		} else {
			setFiltererOrders(orders);
		}
	};

	const getTitle = (title) => {
		if (settings.store_display.replace_sell_title == 1) return title.replace("endita ", "");
		return title;
	};

	if (mode == "cols")
		return (
			<div
				className="col"
				style={productionsCenter ? { backgroundColor: productionsCenter.color } : {}}
			>
				{productionsCenter && (
					<div
						className="title"
						style={{
							fontSize: titleSize + "px",
							lineHeight: titleLine != "normal" ? titleLine + "px" : titleLine,
						}}
					>
						{productionsCenter.name}
					</div>
				)}
				<div
					className="list"
					style={{
						gridTemplateColumns: "repeat(" + cols + ", 1fr)",
					}}
				>
					{filtererOrders.map((order, i) => (
						<div
							key={i}
							className="order"
							style={{ aspectRatio: square == 1 ? "1/1" : "auto" }}
						>
							<div className="name">{order.name}</div>
							{order.scloby_sell_name ? (
								<>
									<div className="name">
										{order.number ? order.number : order.id}
									</div>
									<div className="number" style={{ fontSize: orderSize + "px" }}>
										{getTitle(order.scloby_sell_name)}
									</div>
								</>
							) : (
								<div className="number" style={{ fontSize: orderSize + "px" }}>
									{order.number ? order.number : order.id}
								</div>
							)}
						</div>
					))}
				</div>
			</div>
		);

	if (mode == "line")
		return (
			<>
				{productionsCenter && (
					<div
						className="title"
						style={{
							backgroundColor: productionsCenter
								? productionsCenter.color
								: "transparent",
							fontSize: titleSize + "px",
							lineHeight: titleLine != "normal" ? titleLine + "px" : titleLine,
						}}
					>
						<div>{productionsCenter.name}</div>
					</div>
				)}
				<div
					className="list"
					style={{
						backgroundColor: productionsCenter
							? productionsCenter.color
							: "transparent",
					}}
				>
					{filtererOrders.length > 0 && (
						<div className="order">
							<div className="name">{filtererOrders[0].name}</div>
							{filtererOrders[0].scloby_sell_name ? (
								<>
									<div className="name">
										{filtererOrders[0].number
											? filtererOrders[0].number
											: filtererOrders[0].id}
									</div>
									<div className="number" style={{ fontSize: orderSize + "px" }}>
										{getTitle(filtererOrders[0].scloby_sell_name)}
									</div>
								</>
							) : (
								<div className="number" style={{ fontSize: orderSize + "px" }}>
									{filtererOrders[0].number
										? filtererOrders[0].number
										: filtererOrders[0].id}
								</div>
							)}
						</div>
					)}
				</div>
			</>
		);
}

export default List;
