import { useEffect } from "react";

function Socket({
	socket,
	mandantId,
	shop,
	settings,
	loadSettings,
	handleNewOrder,
	loadProductionsCenters,
}) {
	useEffect(() => {
		console.log("add socket listener");
		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);
		socket.on("subscribed", onSubrscribed);
		socket.on("events", onSocketEvents);

		onConnect();

		return () => {
			console.log("remove socket listener");
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
			socket.off("subscribed", onSubrscribed);
			socket.off("events", onSocketEvents);
		};
	}, [settings]);

	function onConnect() {
		console.log("Socket connected", socket.id);
		socket.emit("subscribe", "m" + mandantId + ".settings.#");
		socket.emit("subscribe", "m" + mandantId + ".production_centers.#");
		socket.emit("subscribe", "m" + mandantId + ".s" + shop.id + ".orders.#");
	}

	function onDisconnect() {
		console.log("Socket disconnected");
	}

	function onSubrscribed(data) {
		console.log(data);
	}

	function onSocketEvents(message) {
		console.log("new socket event: ", message);
		if (message.table == "orders") handleNewOrder(message.data);
		if (message.table == "settings") loadSettings();
		if (message.table == "production_centers") loadProductionsCenters();
	}

	return null;
}

export default Socket;
